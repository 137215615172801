<template>
    <div>
      <b-container class="mt-5 mb-5">
        <b-button to="/manage/organisation/invoices" variant="primary" class="float-right">
          Bekijk facturen
        </b-button>
        <h1 class="fs-4 font-weight-bold mb-4">Financieel</h1>

        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5 mb-0">
                    Omzet <small>(incl. btw)</small>
                </h3>
            </b-card-header>
            <b-card-body>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <td>Event</td>
                            <td>Locatie</td>
                            <td>Bedrag</td>
                            <td>Service</td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(financial,key) in financials">
                            <tr v-if="financial.total_ticket_price > 0 || financial.total_ticket_fee > 0" :key="key">
                                <td>{{ financial.name }}</td>
                                <td>{{ financial.location_name }}</td>
                                <td class="text-right">{{ financial.total_ticket_price|toCurrency }}</td>
                                <td class="text-right">{{ financial.total_ticket_fee|toCurrency }}</td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Totaal</td>
                            <td></td>
                            <td class="text-right">{{ totalRevenue|toCurrency }}</td>
                            <td class="text-right">{{ totalServiceRevenue|toCurrency }}</td>
                        </tr>
                    </tfoot>
                </table>
            </b-card-body>
        </b-card>

        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5 mb-0">
                    Kosten <small>(excl. 21% btw)</small>
                </h3>
            </b-card-header>
            <b-card-body>
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td>Servicekosten</td>
                            <td class="text-right">{{ totalServiceCosts|toCurrency }}</td>
                        </tr>
                        <!--
                        <tr>
                            <td>Transactiekosten</td>
                            <td class="text-right">0</td>
                        </tr>
                        -->
                        <tr>
                            <td>Terugstortingen</td>
                            <td class="text-right">0</td>
                        </tr>
                        <tr>
                            <td><span class="font-weight-bolder">Totaal</span></td>
                            <td class="text-right">{{ total|toCurrency }}</td>
                        </tr>
                        <tr>
                            <td>Btw</td>
                            <td class="text-right">{{ totalTax|toCurrency }}</td>
                        </tr>
                        <tr>
                            <td><span class="font-weight-bolder">Totaal incl. btw</span></td>
                            <td class="text-right">{{ total|toCurrency }}</td>
                        </tr>
                    </tbody>
                </table>
            </b-card-body>
        </b-card>

      </b-container>
    </div>
  </template>
  
  <script>
    export default {
        metaInfo: {
            title: 'Financieel',
        },
        components: {},
        data() {
            return {
                financials: []
            }
        },
        computed: {
            totalRevenue() {
                let total = 0;
                if (this.financials.length) {
                    this.financials.forEach( item => {
                        total += Number(item.total_ticket_price);
                    })
                }
                return total;
            },
            totalServiceRevenue() {
                let total = 0;
                if (this.financials.length) {
                    this.financials.forEach( item => {
                        total += Number(item.total_ticket_fee);
                    })
                }
                return total;
            },
            totalServiceCosts() {
                let total = 0;
                if (this.financials.length) {
                    this.financials.forEach( item => {
                        total += Number(item.total_platform_fee);
                    })
                }
                return total;
            },
            total() {
                return this.totalServiceCosts;
            },
            totalTax() {
                return this.totalServiceCosts - this.totalServiceCosts / ( 1 + 21 / 100);
            }
        },
        methods: {
            getFinancials: function() {
                this.$axios.get("https://api.tixgo.nl/financials")
                    .then( response => {
                        if (response.data) {
                            this.financials = response.data;
                        }
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            }
        },
        created() {
           this.getFinancials();
        },
    }
</script>